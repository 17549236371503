@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');

.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

html{
  background-color: #0d1018;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)' opacity='0.15'/%3E%3C/svg%3E");
  background-size: cover;
  background-attachment: fixed;
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;
  overflow-x: hidden;
}

/* SCROLL STYLE */
body::-webkit-scrollbar {
  width: 5px;               /* largura da scrollbar */
}

body::-webkit-scrollbar-track {
  background: #262D3E;        /* cor da track area */
}

body::-webkit-scrollbar-thumb {
  background-color: #eb6e21;    /* cor do scroll */
  border-radius: 20px;       /* borda do scroll */
}

/* SELEÇÃO DAS PALAVRAS */
::selection {
  background-color: #1d2538;
  color: #ff863b;
}

/* TABLETS */
@media screen and (min-width: 480px) {
  html{
    overflow-x: hidden;
  }
}

/* LAPTOPS OU TELAS MENORES */
@media screen and (min-width: 768px) {
  html{
    overflow-x: hidden;
  }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
  html{
    overflow-x: hidden;
  }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
  html{
    overflow-x: hidden;
  }
}