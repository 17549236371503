.contatoHeader{
    text-align: center;
    padding-top: 80px;
}

.contatoHeader h1{
    color: #fff;
    font-weight: 200;
    font-size: 20px;
}

.contatoHeader h1 .textoGrifado{
    color: #ff863b;
}

.contato p{
    color: #a3a3a3;
    text-align: center;
    margin: 0px 20px;
    font-weight: 300;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.contatoForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.contatoForm1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.contatoForm1 .blockForm1 input{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #a3a3a349;
    height: 40px;
    width: 350px;
    padding-left: 20px;
    margin-bottom: 20px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    letter-spacing: 1px;
    font-size: 12px;
}

.contatoForm1 .blockForm1 input:focus{
    outline: none;
    border-bottom: 2px solid #ff863b;
    letter-spacing: 2px;
    caret-color: #ff863b;
}

.contatoForm1 .blockForm1 input::placeholder{
    color: #a3a3a393;
    letter-spacing: 1px;
}

.contatoForm1 .blockForm2 input{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #a3a3a349;
    height: 40px;
    width: 350px;
    padding-left: 20px;
    margin-bottom: 20px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    letter-spacing: 1px;
    font-size: 12px;
}

.contatoForm1 .blockForm2 input:focus{
    outline: none;
    border-bottom: 2px solid #ff863b;
    letter-spacing: 2px;
    caret-color: #ff863b;
}

.contatoForm1 .blockForm2 input::placeholder{
    color: #a3a3a393;
    letter-spacing: 1px;
}

.contatoForm2 textarea{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #a3a3a349;
    height: 150px;
    width: 350px;
    padding-left: 20px;
    margin-bottom: 20px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    letter-spacing: 1px;
    font-size: 12px;
    resize: none;
}

.contatoForm2 textarea:focus{
    outline: none;
    border-bottom: 2px solid #ff863b;
    letter-spacing: 2px;
    caret-color: #ff863b;
}

.contatoForm2 textarea::placeholder{
    color: #a3a3a393;
    letter-spacing: 1px;
}

.btnForm button{
    border: 2px solid #FF7F32;
    padding: 5px 15px 5px 15px;
    border-radius: 30px 0px 30px 0px;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: ease-in-out 0.3s;
    width: 100px;
    height: 40px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 1px;
}

.btnForm button:hover{
    border: 2px solid #ff863b;
    padding: 10px 25px 10px 25px;
    border-radius: 0px 30px 0px 30px;
    color: #000;
    background-color: #ff863b;
    letter-spacing: 2px;
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .contatoHeader{
        text-align: center;
        padding-top: 150px;
    }
    
    .contatoHeader h1{
        color: #fff;
        font-weight: 200;
        font-size: 24px;
    }
    
    .contatoHeader h1 .textoGrifado{
        color: #ff863b;
    }
    
    .contato p{
        color: #a3a3a3;
        text-align: center;
        margin: 0px 200px;
    }
    
    .contatoForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }
    
    .contatoForm1{
        display: flex;
        flex-direction: row;
        column-gap: 40px;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
    }
    
    .contatoForm1 .blockForm1 input{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #a3a3a349;
        height: 40px;
        width: 300px;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        letter-spacing: 1px;
        font-size: 12px;
    }
    
    .contatoForm1 .blockForm1 input:focus{
        outline: none;
        border-bottom: 2px solid #ff863b;
        letter-spacing: 2px;
        caret-color: #ff863b;
    }
    
    .contatoForm1 .blockForm1 input::placeholder{
        color: #a3a3a393;
        letter-spacing: 1px;
    }
    
    .contatoForm1 .blockForm2 input{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #a3a3a349;
        height: 40px;
        width: 300px;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        letter-spacing: 1px;
        font-size: 12px;
    }
    
    .contatoForm1 .blockForm2 input:focus{
        outline: none;
        border-bottom: 2px solid #ff863b;
        letter-spacing: 2px;
        caret-color: #ff863b;
    }
    
    .contatoForm1 .blockForm2 input::placeholder{
        color: #a3a3a393;
        letter-spacing: 1px;
    }
    
    .contatoForm2 textarea{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #a3a3a349;
        height: 150px;
        width: 660px;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        letter-spacing: 1px;
        font-size: 12px;
        resize: none;
    }
    
    .contatoForm2 textarea:focus{
        outline: none;
        border-bottom: 2px solid #ff863b;
        letter-spacing: 2px;
        caret-color: #ff863b;
    }
    
    .contatoForm2 textarea::placeholder{
        color: #a3a3a393;
        letter-spacing: 1px;
    }
    
    .btnForm button{
        border: 2px solid #FF7F32;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        background-color: transparent;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        transition: ease-in-out 0.3s;
        width: 150px;
        height: 50px;
        letter-spacing: 2px;
        margin-bottom: 60px;
        margin-top: 20px;
    }
    
    .btnForm button:hover{
        border: 2px solid #ff863b;
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
        color: #000;
        background-color: #ff863b;
        letter-spacing: 6px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .contatoHeader{
        text-align: center;
        padding-top: 150px;
    }
    
    .contatoHeader h1{
        color: #fff;
        font-weight: 200;
        font-size: 24px;
    }
    
    .contatoHeader h1 .textoGrifado{
        color: #ff863b;
    }
    
    .contato p{
        color: #a3a3a3;
        text-align: center;
        margin: 0px 500px;
        font-weight: 300;
        font-size: 14px;
        padding-top: 20px;
        padding-bottom: 40px;
    }
    
    .contatoForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }
    
    .contatoForm1{
        display: flex;
        flex-direction: row;
        column-gap: 40px;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
    }
    
    .contatoForm1 .blockForm1 input{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #a3a3a349;
        height: 40px;
        width: 300px;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        letter-spacing: 1px;
        font-size: 12px;
    }
    
    .contatoForm1 .blockForm1 input:focus{
        outline: none;
        border-bottom: 2px solid #ff863b;
        letter-spacing: 2px;
        caret-color: #ff863b;
    }
    
    .contatoForm1 .blockForm1 input::placeholder{
        color: #a3a3a393;
        letter-spacing: 1px;
    }
    
    .contatoForm1 .blockForm2 input{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #a3a3a349;
        height: 40px;
        width: 300px;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        letter-spacing: 1px;
        font-size: 12px;
    }
    
    .contatoForm1 .blockForm2 input:focus{
        outline: none;
        border-bottom: 2px solid #ff863b;
        letter-spacing: 2px;
        caret-color: #ff863b;
    }
    
    .contatoForm1 .blockForm2 input::placeholder{
        color: #a3a3a393;
        letter-spacing: 1px;
    }
    
    .contatoForm2 textarea{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #a3a3a349;
        height: 150px;
        width: 660px;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        letter-spacing: 1px;
        font-size: 12px;
        resize: none;
    }
    
    .contatoForm2 textarea:focus{
        outline: none;
        border-bottom: 2px solid #ff863b;
        letter-spacing: 2px;
        caret-color: #ff863b;
    }
    
    .contatoForm2 textarea::placeholder{
        color: #a3a3a393;
        letter-spacing: 1px;
    }
    
    .btnForm button{
        border: 2px solid #FF7F32;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        background-color: transparent;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        transition: ease-in-out 0.3s;
        width: 150px;
        height: 50px;
        letter-spacing: 2px;
        margin-bottom: 60px;
        margin-top: 20px;
    }
    
    .btnForm button:hover{
        border: 2px solid #ff863b;
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
        color: #000;
        background-color: #ff863b;
        letter-spacing: 6px;
    }
}