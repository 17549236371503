.container{
    padding-bottom: 80px;
    overflow-x: hidden;
}

.beneficioTitulo h2{
    text-align: center;
    color: #fff;
    font-weight: 200;
    padding-bottom: 24px;
    font-size: 20px;
}

.textoMarcado {
    color: #ff863b;
}

.banners{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin: 0px 30px;
}

.banner{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border: 2px solid #00000050;
    border-radius: 10px;
    padding: 10px;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    box-shadow: 5px 5px 8px #000000c5;
}

.banner:hover {
    border: 2px solid #bb5f26;
}

.banner:hover .icone {
    color: #bb5f26;
}

.banner:hover .iconeMovimento {
    color: #d37134;
    animation: aumentar 0.3s forwards;
}

.banner:not(:hover) .iconeMovimento {
    animation: diminuir 0.3s forwards;
}

.banner:hover .tituloBanner h2 {
    color: #ff863b;
}

.numeração{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a3a3a3;
    font-weight: 400;
    font-size: 14px;
}

.icone{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000098;
    transition: ease-in-out 0.3s;
}

.iconeMovimento{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000098;
    transition: ease-in-out 0.3s;
    padding-right: 0px;
    padding-bottom: 10px;
}

.tituloBanner h2{
    font-size: 18px;
    font-weight: 400;
    color: #fff;
}

.tituloBanner p{
    font-size: 14px;
    font-weight: 200;
    color: #a3a3a3;
    margin-right: 0px;
}

@keyframes aumentar {
    from {
        transform: scale(1);
    } 
    to {
        transform: scale(1.5);
    }
}

@keyframes diminuir {
    from {
        transform: scale(1.5);
    } 
    to {
        transform: scale(1);
    }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .banner{
        flex-direction: row;
        column-gap: 20px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .container{
        padding-bottom: 80px;
    }
    
    .beneficioTitulo h2{
        text-align: center;
        color: #fff;
        font-weight: 200;
        padding-bottom: 24px;
    }
    
    .textoMarcado {
        color: #ff863b;
    }
    
    .banners{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin: 0px 300px;
    }
    
    .banner{
        display: flex;
        flex-direction: row;
        column-gap: 100px;
        border: 2px solid #00000050;
        border-radius: 10px;
        padding: 10px;
        transition: ease-in-out 0.3s;
        cursor: pointer;
        box-shadow: 5px 5px 8px #000000c5;
    }
    
    .banner:hover {
        border: 2px solid #bb5f26;
    }
    
    .banner:hover .icone {
        color: #bb5f26;
    }
    
    .banner:hover .iconeMovimento {
        color: #d37134;
        animation: aumentar 0.3s forwards;
    }
    
    .banner:not(:hover) .iconeMovimento {
        animation: diminuir 0.3s forwards;
    }
    
    .banner:hover .tituloBanner h2 {
        color: #ff863b;
    }
    
    .numeração{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #a3a3a3;
        font-weight: 400;
    }
    
    .icone{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #00000098;
        transition: ease-in-out 0.3s;
    }
    
    .iconeMovimento{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #00000098;
        transition: ease-in-out 0.3s;
        padding-right: 30px;
    }
    
    .tituloBanner h2{
        font-size: 20px;
        font-weight: 400;
        color: #fff;
    }
    
    .tituloBanner p{
        font-size: 14px;
        font-weight: 200;
        color: #a3a3a3;
        margin-right: 300px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1440px) {
    .banners{
        margin: 0px 150px;
    }
}