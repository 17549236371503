.containerPlansServices{
    overflow-x: hidden;
}

.containerPlansServices h2{
    text-align: center;
    font-weight: 200;
    font-size: 20px;
    padding-bottom: 40px;
    color: #fff
}

.textoGrifado{
    color: #ff863b;
}

.PlansServices{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin: 0px 30px;
    padding-bottom: 60px;
}

.detailsGuardian{
    background-color: #080a1171;
    border: 1px solid #E0E0E0;
    border-radius: 50px;
}

.detailsGuardian h3{
    color: #E0E0E0;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsGuardian p{
    color: #a3a3a3;
    text-align: justify;
    padding: 30px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

.detailsUpdate{
    background-color: #080a1171;
    border: 1px solid #ff67de;
    border-radius: 50px;
}

.detailsUpdate h3{
    color: #ff67de;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsUpdate p{
    color: #a3a3a3;
    text-align: justify;
    padding: 30px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .PlansServices{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        column-gap: 20px;
        margin: 0px 30px;
        padding-bottom: 60px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .containerPlansServices h2{
        text-align: center;
        font-weight: 200;
        font-size: 24px;
        padding-bottom: 40px;
        color: #fff
    }
    
    .textoGrifado{
        color: #ff863b;
    }
    
    .PlansServices{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 0px 200px;
        column-gap: 40px;
        padding-bottom: 60px;
    }
    
    .detailsGuardian{
        background-color: #080a1171;
        border: 1px solid #E0E0E0;
        border-radius: 50px;
    }
    
    .detailsGuardian h3{
        color: #E0E0E0;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsGuardian p{
        color: #a3a3a3;
        text-align: justify;
        padding: 30px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
    
    .detailsUpdate{
        background-color: #080a1171;
        border: 1px solid #ff67de;
        border-radius: 50px;
    }
    
    .detailsUpdate h3{
        color: #ff67de;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsUpdate p{
        color: #a3a3a3;
        text-align: justify;
        padding: 30px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
}