.container h1{
    font-size: 20px;
    text-align: center;
    font-weight: 300;
    color: #fff;
    padding-bottom: 20px;
    padding-top: 20px;
}

.textoGrifado{
    color: #ff863b;
}

.containerService{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 0px 15px;
}

.detailsService{
    background-color: #08090e86;
    border-radius: 15px;
    height: 350px;
    box-shadow: 5px 5px 10px #000000;
}

.detailsService h2{
    color: #d1d1d1;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 30px;
    margin: 0px;
}

.detailsService .linhaVertical{
    background-color: #d1d1d1;
    width: 80px;
    height: 3px;
    margin-left: 30px;
    margin-top: 5px;
    border-radius: 20px;
}

.listService ul {
    list-style: none;
    padding-left: 30px;
    padding-top: 10px;
}

.listService ul li{
    color: #fff;
    font-weight: 200;
    padding-bottom: 10px;
    font-size: 12px;
}

.listService ul li i svg{
    padding-right: 10px;
}

/* GUARDIAN */
.detailsService .guardian{
    color: #838383;
    font-weight: 300;
    padding-left: 30px;
    margin: 0px;
}

/* UPADATE */
.detailsService .update{
    color: #ff67de;
    font-weight: 300;
    padding-left: 30px;
    margin: 0px;
}

.btnWapp{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnWapp a{
    border: 2px solid #FF7F32;
    padding: 5px 15px 5px 15px;
    border-radius: 30px 0px 30px 0px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: ease-in-out 0.3s;
    font-size: 14px;
}

.btnWapp a:hover{
    border: 2px solid #ff863b;
    padding: 5px 15px 5px 15px;
    border-radius: 0px 30px 0px 30px;
    color: #000;
    background-color: #ff863b;
}

/* TABLETS */
@media screen and (min-width: 480px) {
    .containerService{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
    
    .detailsService{
        height: 270px;
    }
    
    .detailsService h2{
        font-size: 18px;
        padding-left: 20px;
    }

    .detailsService .linhaVertical{
        margin-left: 20px;
    }
    
    .listService ul {
        list-style: none;
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 10px;
    }
    
    .listService ul li i svg{
        padding-right: 10px;
    }

    /* GUARDIAN */
    .detailsService .guardian{
        padding-left: 20px;
    }

    /* UPDATE */
    .detailsService .update{
        padding-left: 20px;
    }
}

/* LAPTOPS OU TELAS MENORES */
@media screen and (min-width: 768px) {
    .containerService{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        column-gap: 30px;
    }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .container h1{
        font-size: 22px;
    }
    
    .containerService{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        row-gap: 20px;
        margin: 0px 15px;
    }
    
    .detailsService{
        background-color: #08090e86;
        border-radius: 15px;
        height: 350px;
        box-shadow: 5px 5px 10px #000000;
    }
    
    .detailsService h2{
        color: #d1d1d1;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 30px;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsService .linhaVertical{
        background-color: #d1d1d1;
        width: 80px;
        height: 3px;
        margin-left: 30px;
        margin-top: 5px;
        border-radius: 20px;
    }
    
    .listService ul {
        list-style: none;
        padding-left: 30px;
        padding-top: 10px;
    }
    
    .listService ul li{
        color: #fff;
        font-weight: 200;
        padding-bottom: 10px;
        font-size: 14px
    }
    
    /* GUARDIAN */
    .detailsService .guardian{
        color: #838383;
        font-size: 14px;
        font-weight: 300;
        padding-left: 30px;
        margin: 0px;
    }
    
    /* UPADATE */
    .detailsService .update{
        color: #ff67de;
        font-size: 14px;
        font-weight: 300;
        padding-left: 30px;
        margin: 0px;
    }
}