.container{
    display: flex;
    flex-direction: column;
    margin: 50px 0px;
    overflow-x: hidden;
}

.aboutSection{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}

.textoSection{
    display: flex;
    flex-direction: column;
}

.titulo{
    color: #fff;
    font-weight: 200;
    font-size: 20px;
}

.tituloMarcado{
    color: #ff863b;
}

.texto{
    font-weight: 200;
    color: #fff;
    font-size: 14px;
}

.botaoOrçamento{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.botaoOrçamento a{
    border: 2px solid #FF7F32;
    padding: 10px 25px 10px 25px;
    border-radius: 30px 0px 30px 0px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: ease-in-out 0.3s;
    font-size: 14px;
}

.botaoOrçamento a:hover{
    border: 2px solid #ff863b;
    padding: 10px 25px 10px 25px;
    border-radius: 0px 30px 0px 30px;
    color: #000;
    background-color: #ff863b;
}

.disco{
    animation: rotate 15s linear infinite;
    height: 150px;
    width: 150px;
}

@keyframes rotate {
    from {
      transform: rotate(0deg); /* Inicia sem rotação */
    }
    to {
      transform: rotate(360deg); /* Roda até 360 graus (uma volta completa) */
    }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .aboutSection{
        margin: 0px 80px;
    }

    .titulo{
        font-size: 20px;
    }

    .texto{
        font-size: 14px;
    }

    .botaoOrçamento a{
        font-size: 14px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .container{
        display: flex;
        flex-direction: column;
        margin: 80px 0px;
    }
    
    .aboutSection{
        display: flex;
        flex-direction: row;
        column-gap: 80px;
        justify-content: center;
        align-items: center;
        margin: 0px 400px;
    }
    
    .textoSection{
        display: flex;
        flex-direction: column;
    }
    
    .titulo{
        color: #fff;
        font-weight: 200;
        font-size: 24px;
    }
    
    .tituloMarcado{
        color: #ff863b;
    }
    
    .texto{
        font-weight: 300;
        color: #fff;
    }
    
    .botaoOrçamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    
    .botaoOrçamento a{
        border: 2px solid #FF7F32;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        transition: ease-in-out 0.3s;
    }
    
    .botaoOrçamento a:hover{
        border: 2px solid #ff863b;
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
        color: #000;
        background-color: #ff863b;
    }
    
    .disco{
        animation: rotate 15s linear infinite;
        height: 200px;
        width: 200px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1440px) {
    .aboutSection{
        margin: 0px 200px;
    }
}