.containerPlans{
    overflow-x: hidden;
}

.containerPlans h2{
    text-align: center;
    font-weight: 200;
    font-size: 20px;
    padding-bottom: 20px;
    color: #fff
}

.textoGrifado{
    color: #ff863b;
}

.plans{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin: 0px 30px;
    padding-bottom: 40px;
}

.detailsEssential{
    background-color: #080a1171;
    border: 1px solid #FF9900;
    border-radius: 50px;
}

.detailsEssential h3{
    color: #FF9900;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsEssential p{
    color: #a3a3a3;
    text-align: justify;
    padding: 25px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

.detailsPremium{
    background-color: #080a1171;
    border: 1px solid #1E90FF;
    border-radius: 50px;
}

.detailsPremium h3{
    color: #1E90FF;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsPremium p{
    color: #a3a3a3;
    text-align: justify;
    padding: 25px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

.detailsElite{
    background-color: #080a1171;
    border: 1px solid #9400D3;
    border-radius: 50px;
}

.detailsElite h3{
    color: #9400D3;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsElite p{
    color: #a3a3a3;
    text-align: justify;
    padding: 25px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .plans{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        column-gap: 20px;
        margin: 0px 30px;
        padding-bottom: 40px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .containerPlans h2{
        text-align: center;
        font-weight: 200;
        font-size: 24px;
        padding-bottom: 40px;
        color: #fff
    }
    
    .textoGrifado{
        color: #ff863b;
    }
    
    .plans{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 0px 200px;
        column-gap: 40px;
        padding-bottom: 60px;
    }
    
    .detailsEssential{
        background-color: #080a1171;
        border: 1px solid #FF9900;
        border-radius: 50px;
    }
    
    .detailsEssential h3{
        color: #FF9900;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsEssential p{
        color: #a3a3a3;
        text-align: justify;
        padding: 25px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
    
    .detailsPremium{
        background-color: #080a1171;
        border: 1px solid #1E90FF;
        border-radius: 50px;
    }
    
    .detailsPremium h3{
        color: #1E90FF;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsPremium p{
        color: #a3a3a3;
        text-align: justify;
        padding: 25px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
    
    .detailsElite{
        background-color: #080a1171;
        border: 1px solid #9400D3;
        border-radius: 50px;
    }
    
    .detailsElite h3{
        color: #9400D3;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsElite p{
        color: #a3a3a3;
        text-align: justify;
        padding: 25px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
}