.containerNav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(10px);
    z-index: 1000;
    overflow-x: hidden;
}

.logo img{
    display: none;
}

nav ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    padding: 0px 15px;
}

nav ul li .link{
    font-weight: 200;
}

.link{
    text-decoration: none;
    color: #fff;
    font-size: 14px;
}

.link:hover{
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-color: #FF7F32;
    transition: all 0.5s;
}

.linkContato{
    text-decoration: none;
    font-weight: 300;
    border: 2px solid #FF7F32;
    padding: 5px 15px 5px 15px;
    border-radius: 30px 0px 30px 0px;
    color: #ffa66f;
    transition: all 0.3s;
    font-size: 14px;
}

.linkContato:hover{
    font-weight: 300;
    border-radius: 0px 30px 0px 30px;
    color: #000000;
    background-color: #ff863b;
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .containerNav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(0, 0, 0, 0.24);
        backdrop-filter: blur(10px);
        z-index: 1000;
        overflow-x: hidden;
    }
    
    .logo img{
        height: 70px;
        display: block;
        padding-left: 50px;
    }
    
    nav ul{
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        padding-right: 50px;
    }
    
    nav ul li .link{
        font-weight: 200;
    }
    
    .link{
        text-decoration: none;
        color: #fff;
        font-size: 14px;
    }
    
    .link:hover{
        text-decoration: underline;
        text-underline-offset: 6px;
        text-decoration-color: #FF7F32;
        transition: all 0.5s;
    }
    
    .linkContato{
        text-decoration: none;
        font-weight: 300;
        border: 2px solid #FF7F32;
        padding: 5px 15px 5px 15px;
        border-radius: 30px 0px 30px 0px;
        color: #ffa66f;
        transition: all 0.3s;
        font-size: 14px;
    }
    
    .linkContato:hover{
        font-weight: 300;
        border-radius: 0px 30px 0px 30px;
        color: #000000;
        background-color: #ff863b;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .containerNav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(0, 0, 0, 0.24);
        backdrop-filter: blur(10px);
        z-index: 1000;
        padding: 5px 0px;
    }
    
    .logo img{
        height: 70px;
        display: block;
        padding-left: 50px;
    }
    
    nav ul{
        list-style: none;
        display: flex;
        flex-direction: row;
        column-gap: 30px;
        padding-right: 50px;
    }
    
    nav ul li .link{
        font-weight: 200;
    }
    
    .link{
        text-decoration: none;
        color: #fff;
    }
    
    .link:hover{
        text-decoration: underline;
        text-underline-offset: 6px;
        text-decoration-color: #FF7F32;
        transition: all 0.5s;
    }
    
    .linkContato{
        text-decoration: none;
        font-weight: 300;
        border: 2px solid #FF7F32;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #ffa66f;
        transition: all 0.3s;
    }
    
    .linkContato:hover{
        font-weight: 300;
        border-radius: 0px 30px 0px 30px;
        color: #000000;
        background-color: #ff863b;
    }
}