.container{
    padding-top: 180px;
    text-align: center;
    color: #fff;
    font-weight: 200;
    padding-bottom: 150px;
}

.container .link{
    color: #fff;
    text-decoration: none;
    letter-spacing: 2px;
    border-bottom: 2px solid #000000a1;
}
