.containerHowWorks{
    background-color: #00000056;
    border-radius: 0px 0px 40px 40px;
    margin-bottom: 20px;
    overflow-x: hidden;
}

.containerHowWorks h2{
    padding: 30px 0px;
    text-align: center;
    font-weight: 200;
    font-size: 20px;
    color: #fff;
}

.textoGrifado{
    color: #ff863b;
}

.numeração{
    color: #fff;
    font-weight: 500;
    word-spacing: 10px;
}

.containerDefiniçao{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 0px 30px;
    padding-bottom: 60px;
}

.definiçao{
    background-color: #0d1018;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)' opacity='0.15'/%3E%3C/svg%3E");
    text-align: justify;
    padding: 20px 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 8px #000000c5;
}

.definiçao h3{
    text-align: center;
    color: #fff;
    font-weight: 200;
    padding-bottom: 30px;
    font-size: 14px;
}

.definiçao p{
    color: #a3a3a3;
    font-weight: 200;
    font-size: 12px;
}

.espaço{
    padding-bottom: 10px;
}

/* TABLETS */
@media screen and (min-width: 480px) {}

/* LAPTOPS OU TELAS MENORES */
@media screen and (min-width: 768px) {}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .definiçao h3{
        padding-bottom: 20px;
    }
    
    .definiçao p{
        margin: 0px 150px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .containerHowWorks{
        background-color: #00000056;
        border-radius: 0px 0px 40px 40px;
        margin-bottom: 60px;
    }
    
    .containerHowWorks h2{
        padding: 60px 0px;
        text-align: center;
        font-weight: 200;
        font-size: 24px;
    }
    
    .textoGrifado{
        color: #ff863b;
    }
    
    .containerDefiniçao{
        display: flex;
        flex-direction: row;
        column-gap: 80px;
        padding: 0px 80px;
        padding-bottom: 60px;
    }
    
    .definiçao{
        background-color: #0d1018;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)' opacity='0.15'/%3E%3C/svg%3E");
        text-align: justify;
        padding: 40px 60px;
        border-radius: 20px;
        box-shadow: 5px 5px 8px #000000c5;
    }
    
    .definiçao h3{
        text-align: center;
        color: #fff;
        font-weight: 200;
        padding-bottom: 30px;
        font-size: 18px;
    }
    
    .definiçao p{
        color: #a3a3a3;
        font-weight: 200;
        font-size: 14px;
    }
    
    .espaço{
        padding-bottom: 60px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1440px) {
    .containerDefiniçao{
        column-gap: 40px;
    }

    .definiçao p{
        color: #a3a3a3;
        font-weight: 200;
        font-size: 12px;
        margin: 0px;
    }
}