.container h1{
    font-size: 20px;
    text-align: center;
    font-weight: 300;
    color: #fff;
    padding-bottom: 20px;
    padding-top: 20px;
}

.textoGrifado{
    color: #ff863b;
}

.containerPlans{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 0px 15px;
}

.details{
    background-color: #08090e86;
    border-radius: 15px;
    height: 450px;
    box-shadow: 5px 5px 10px #000000;
}

.details h2{
    color: #d1d1d1;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 30px;
    margin: 0px;
}

.details .linhaVertical{
    background-color: #d1d1d1;
    width: 80px;
    height: 3px;
    margin-left: 30px;
    margin-top: 5px;
    border-radius: 20px;
}

.list ul {
    list-style: none;
    padding-left: 30px;
    padding-top: 10px;
}

.list ul li{
    color: #fff;
    font-weight: 200;
    padding-bottom: 10px;
    font-size: 12px;
}

.list ul li i svg{
    padding-right: 10px;
}

/* ESSENTIAL */
.details .essential{
    color: #FF9900;
    font-weight: 300;
    padding-left: 30px;
    margin: 0px;
}

/* PREMIUM */
.details .premium{
    color: #1E90FF;
    font-weight: 300;
    padding-left: 30px;
    margin: 0px;
}

/* ELITE */
.details .elite{
    color: #8c00c9;
    font-weight: 300;
    padding-left: 30px;
    margin: 0px;
}

.btnWapp{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnWapp a{
    border: 2px solid #FF7F32;
    padding: 5px 15px 5px 15px;
    border-radius: 30px 0px 30px 0px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: ease-in-out 0.3s;
    font-size: 14px;
}

.btnWapp a:hover{
    border: 2px solid #ff863b;
    padding: 5px 15px 5px 15px;
    border-radius: 0px 30px 0px 30px;
    color: #000;
    background-color: #ff863b;
}

/* TABLETS */
@media screen and (min-width: 480px) {
    .containerPlans{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
    
    .details{
        height: 570px;
    }
    
    .details h2{
        font-size: 18px;
        padding-left: 20px;
    }

    .details .linhaVertical{
        margin-left: 20px;
    }
    
    .list ul {
        list-style: none;
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 10px;
    }
    
    .list ul li i svg{
        padding-right: 10px;
    }

    /* ESSENTIAL */
    .details .essential{
        padding-left: 20px;
    }

/* PREMIUM */
    .details .premium{
        padding-left: 20px;
    }

/* ELITE */
    .details .elite{
        padding-left: 20px;
    }
}

/* LAPTOPS OU TELAS MENORES */
@media screen and (min-width: 768px) {
    .containerPlans{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        column-gap: 30px;
    }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .container h1{
        font-size: 22px;
    }
    
    .containerPlans{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        row-gap: 20px;
        margin: 0px 15px;
    }
    
    .details{
        background-color: #08090e86;
        border-radius: 15px;
        height: 500px;
        box-shadow: 5px 5px 10px #000000;
    }
    
    .details h2{
        color: #d1d1d1;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 30px;
        margin: 0px;
        font-size: 18px;
    }
    
    .details .linhaVertical{
        background-color: #d1d1d1;
        width: 80px;
        height: 3px;
        margin-left: 30px;
        margin-top: 5px;
        border-radius: 20px;
    }
    
    .list ul {
        list-style: none;
        padding-left: 30px;
        padding-top: 10px;
    }
    
    .list ul li{
        color: #fff;
        font-weight: 200;
        padding-bottom: 10px;
        font-size: 14px;
    }
    
    /* ESSENTIAL */
    .details .essential{
        color: #FF9900;
        font-size: 14px;
        font-weight: 300;
        padding-left: 30px;
        margin: 0px;
    }
    
    /* PREMIUM */
    .details .premium{
        color: #1E90FF;
        font-size: 14px;
        font-weight: 300;
        padding-left: 30px;
        margin: 0px;
    }
    
    /* ELITE */
    .details .elite{
        color: #8c00c9;
        font-size: 14px;
        font-weight: 300;
        padding-left: 30px;
        margin: 0px;
    }
}