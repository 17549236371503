.containerExtraPlans{
    overflow-x: hidden;
}

.containerExtraPlans h2{
    text-align: center;
    font-weight: 200;
    font-size: 20px;
    padding-bottom: 40px;
    color: #fff
}

.textoGrifado{
    color: #ff863b;
}

.ExtraPlans{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin: 0px 30px;
    padding-bottom: 60px;
}

.detailsBubble{
    background-color: #080a1171;
    border: 1px solid #7FFFD4;
    border-radius: 50px;
}

.detailsBubble h3{
    color: #7FFFD4;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsBubble p{
    color: #a3a3a3;
    text-align: justify;
    padding: 30px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

.detailsCreative{
    background-color: #080a1171;
    border: 1px solid #DC143C;
    border-radius: 50px;
}

.detailsCreative h3{
    color: #DC143C;
    text-align: center;
    padding-top: 20px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
}

.detailsCreative p{
    color: #a3a3a3;
    text-align: justify;
    padding: 30px 0px;
    font-weight: 200;
    margin: 0px 20px;
    font-size: 14px;
}

.btns{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.btnWapp a{
    border: 2px solid #FF7F32;
    padding: 5px 15px 5px 15px;
    border-radius: 30px 0px 30px 0px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: ease-in-out 0.3s;
    font-size: 14px;
}

.btnWapp a:hover{
    border: 2px solid #ff863b;
    padding: 5px 15px 5px 15px;
    border-radius: 0px 30px 0px 30px;
    color: #000;
    background-color: #ff863b;
}

.btnPlanos a{
    border: 2px solid #FF7F32;
    padding: 5px 15px 5px 15px;
    border-radius: 30px 0px 30px 0px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: ease-in-out 0.3s;
    font-size: 14px;
}

.btnPlanos a:hover{
    border: 2px solid #ff863b;
    padding: 5px 15px 5px 15px;
    border-radius: 0px 30px 0px 30px;
    color: #000;
    background-color: #ff863b;
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .ExtraPlans{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        column-gap: 20px;
        margin: 0px 30px;
        padding-bottom: 60px;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .containerExtraPlans h2{
        text-align: center;
        font-weight: 200;
        font-size: 24px;
        padding-bottom: 40px;
        color: #fff
    }
    
    .textoGrifado{
        color: #ff863b;
    }
    
    .ExtraPlans{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 0px 200px;
        column-gap: 40px;
        padding-bottom: 60px;
    }
    
    .detailsBubble{
        background-color: #080a1171;
        border: 1px solid #7FFFD4;
        border-radius: 50px;
    }
    
    .detailsBubble h3{
        color: #7FFFD4;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsBubble p{
        color: #a3a3a3;
        text-align: justify;
        padding: 30px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
    
    .detailsCreative{
        background-color: #080a1171;
        border: 1px solid #DC143C;
        border-radius: 50px;
    }
    
    .detailsCreative h3{
        color: #DC143C;
        text-align: center;
        padding-top: 20px;
        font-weight: 400;
        margin: 0px;
        font-size: 18px;
    }
    
    .detailsCreative p{
        color: #a3a3a3;
        text-align: justify;
        padding: 30px 0px;
        font-weight: 200;
        margin: 0px 20px;
        font-size: 14px;
    }
    
    .btns{
        display: flex;
        flex-direction: row;
        column-gap: 40px;
        justify-content: center;
        align-items: center;
        padding-bottom: 100px;
    }
    
    .btnWapp a{
        border: 2px solid #FF7F32;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        transition: ease-in-out 0.3s;
    }
    
    .btnWapp a:hover{
        border: 2px solid #ff863b;
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
        color: #000;
        background-color: #ff863b;
    }
    
    .btnPlanos a{
        border: 2px solid #FF7F32;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        transition: ease-in-out 0.3s;
    }
    
    .btnPlanos a:hover{
        border: 2px solid #ff863b;
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
        color: #000;
        background-color: #ff863b;
    }
}