.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    overflow-x: hidden;
}

.containerMain{
    margin: 0px 25px;
}

.title{
    text-align: center;
    font-size: 38px;
    font-weight: 300;
    color: #FF7F32;
    margin: 0px;
    padding-bottom: 15px;
    padding-top: 40px;
}

.subtitle{
    text-align: justify;
    font-size: 20px;
    font-weight: 100;
    color: #fff;
    margin: 0px;
}

.subtitle1{
    font-weight: 500;
}

.subtitle2{
    padding-top: 15px;
    text-align: justify;
    font-size: 16px;
    font-weight: 100;
    color: #fff;
    margin: 0px;
}

.botaoOrçamento{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 220px;
}

.botaoOrçamento a{
    background: linear-gradient(45deg, #f16f19, #ff863b, #fdab31, #f16f19, #ff863b, #fdab31);
    background-size: 300% 100%;
    padding: 10px 25px 10px 25px;
    border-radius: 30px 0px 30px 0px;
    color: #000;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    animation: gradientMove 3s infinite linear;
    transition: 0.3s ease-in-out;
}

.botaoOrçamento a:hover{
    padding: 10px 25px 10px 25px;
    border-radius: 0px 30px 0px 30px;
}

.divisoria img{
    width: 99vw;
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

/* TABLETS --------------------------------------------------------------------------------------- */
@media screen and (min-width: 480px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
    }
    
    .containerMain{
        margin: 0px 60px;
    }
    
    .title{
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: #FF7F32;
        margin: 0px;
        padding-bottom: 30px;
        padding-top: 80px;
    }
    
    .subtitle{
        text-align: justify;
        font-size: 22px;
        font-weight: 100;
        color: #fff;
        margin: 0px;
    }

    
    .subtitle1{
        font-weight: 500;
    }
    
    .subtitle2{
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: justify;
        font-size: 16px;
        font-weight: 200;
        color: #fff;
        margin: 0px;
    }

    .botaoOrçamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 180px;
    }
    
    .botaoOrçamento a{
        background: linear-gradient(45deg, #f16f19, #ff863b, #fdab31, #f16f19, #ff863b, #fdab31);
        background-size: 300% 100%;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #000;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        animation: gradientMove 3s infinite linear;
        transition: 0.3s ease-in-out;
    }
    
    .botaoOrçamento a:hover{
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
    }
    
    .divisoria img{
        width: 99vw;
    }
}

/* LAPTOPS OU TELAS MENORES --------------------------------------------------------------------------------------- */
@media screen and (min-width: 768px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
    }
    
    .containerMain{
        margin: 0px 180px;
    }
    
    .title{
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: #FF7F32;
        margin: 0px;
        padding-bottom: 30px;
        padding-top: 80px;
    }
    
    .subtitle{
        text-align: justify;
        font-size: 22px;
        font-weight: 100;
        color: #fff;
        margin: 0px;
    }

    
    .subtitle1{
        font-weight: 500;
    }
    
    .subtitle2{
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: justify;
        font-size: 16px;
        font-weight: 200;
        color: #fff;
        margin: 0px;
    }

    .botaoOrçamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 140px;
    }
    
    .botaoOrçamento a{
        background: linear-gradient(45deg, #f16f19, #ff863b, #fdab31, #f16f19, #ff863b, #fdab31);
        background-size: 300% 100%;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #000;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        animation: gradientMove 3s infinite linear;
        transition: 0.3s ease-in-out;
    }
    
    .botaoOrçamento a:hover{
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
    }
    
    .divisoria img{
        width: 99vw;
    }
}

/* DESKTOP --------------------------------------------------------------------------------------- */
@media screen and (min-width: 1024px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
    }
    
    .containerMain{
        margin: 0px 180px;
    }
    
    .title{
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: #FF7F32;
        margin: 0px;
        padding-bottom: 30px;
        padding-top: 80px;
    }
    
    .subtitle{
        text-align: justify;
        font-size: 22px;
        font-weight: 100;
        color: #fff;
        margin: 0px;
    }

    
    .subtitle1{
        font-weight: 500;
    }
    
    .subtitle2{
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: justify;
        font-size: 16px;
        font-weight: 200;
        color: #fff;
        margin: 0px;
    }

    .botaoOrçamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 140px;
    }
    
    .botaoOrçamento a{
        background: linear-gradient(45deg, #f16f19, #ff863b, #fdab31, #f16f19, #ff863b, #fdab31);
        background-size: 300% 100%;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #000;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        animation: gradientMove 3s infinite linear;
        transition: 0.3s ease-in-out;
    }
    
    .botaoOrçamento a:hover{
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
    }
    
    .divisoria img{
        width: 99vw;
    }
}

/* DESKTOP MAIORES --------------------------------------------------------------------------------------- */
@media screen and (min-width: 1200px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
    }
    
    .containerMain{
        margin: 0px 280px;
    }
    
    .title{
        text-align: center;
        font-size: 38px;
        font-weight: 300;
        color: #FF7F32;
        margin: 0px;
        padding-bottom: 30px;
        padding-top: 80px;
    }
    
    .subtitle{
        text-align: justify;
        font-size: 26px;
        font-weight: 100;
        color: #fff;
        margin: 0px;
    }

    
    .subtitle1{
        font-weight: 500;
    }
    
    .subtitle2{
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: justify;
        font-size: 18px;
        font-weight: 200;
        color: #fff;
        margin: 0px;
    }

    .botaoOrçamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 80px;
    }
    
    .botaoOrçamento a{
        background: linear-gradient(45deg, #f16f19, #ff863b, #fdab31, #f16f19, #ff863b, #fdab31);
        background-size: 300% 100%;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #000;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        animation: gradientMove 3s infinite linear;
        transition: 0.3s ease-in-out;
    }
    
    .botaoOrçamento a:hover{
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
    }
    
    .divisoria img{
        width: 99vw;
    }
}

/* DESKTOP MAIORES AINDA --------------------------------------------------------------------------------------- */
@media screen and (min-width: 1440px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
    }
    
    .containerMain{
        margin: 0px 380px;
    }
    
    .title{
        text-align: center;
        font-size: 40px;
        font-weight: 300;
        color: #FF7F32;
        margin: 0px;
        padding-bottom: 30px;
        padding-top: 80px;
    }
    
    .subtitle{
        text-align: justify;
        font-size: 28px;
        font-weight: 200;
        color: #fff;
        margin: 0px;
    }

    
    .subtitle1{
        font-weight: 500;
    }
    
    .subtitle2{
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: justify;
        font-size: 22px;
        font-weight: 200;
        color: #fff;
        margin: 0px;
    }

    .botaoOrçamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 80px;
    }
    
    .botaoOrçamento a{
        background: linear-gradient(45deg, #f16f19, #ff863b, #fdab31, #f16f19, #ff863b, #fdab31);
        background-size: 300% 100%;
        padding: 10px 25px 10px 25px;
        border-radius: 30px 0px 30px 0px;
        color: #000;
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        animation: gradientMove 3s infinite linear;
        transition: 0.3s ease-in-out;
    }
    
    .botaoOrçamento a:hover{
        padding: 10px 25px 10px 25px;
        border-radius: 0px 30px 0px 30px;
    }
    
    .divisoria img{
        width: 99vw;
    }
}
