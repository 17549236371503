.container{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content:space-evenly;
    background-color: #00000056;
    padding: 20px;
    overflow-x: hidden;
}

.secao1{
    display: flex;
    flex-direction: column;
}

.secao1 .img{
    padding-bottom: 25px;
}

.secao1 a{
    color: #c0c0c0;
    text-decoration: none;
    font-weight: 200;
    font-size: 12px;
    padding-bottom: 10px;
    transition: ease-in-out 0.2s;
}

.secao1 a:hover{
    color: #ff863b;
}

.secao2{
    display: flex;
    flex-direction: column;
}

.secao2 h2{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.secao2 a{
    color: #c0c0c0;
    text-decoration: none;
    font-weight: 200;
    font-size: 12px;
    padding-bottom: 5px;
    transition: ease-in-out 0.2s;
}

.secao2 a:hover{
    color: #ff863b;
}

.secao3{
    display: flex;
    flex-direction: column;
}

.secao3 h2{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    margin-bottom: 1px;
}

.secao3 p{
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    margin-bottom: 8px;
    border-bottom: 2px solid #ffffff28;
    padding-bottom: 3px;
}

.secao3 span{
    font-weight: 200;
    font-size: 12px;
    color: #ff863b;
}

.secao4 h2{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.secao4 a{
    color: #c0c0c0;
    text-decoration: none;
    font-weight: 200;
    font-size: 12px;
    padding-bottom: 5px;
    transition: ease-in-out 0.2s;
}

.secao4 a:hover{
    color: #ff863b;
}

.secao5 .redes{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.secao5 h2{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.secao5 .redes a svg{
    color: #c0c0c0;
    transition: ease-in-out 0.4s;
    height: 20px;
}

.secao5 .redes a svg:hover{
    color: #ff863b;
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .container{
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        justify-content:space-evenly;
        background-color: #00000056;
        padding: 20px;
        overflow-x: hidden;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .container{
        display: flex;
        flex-direction: row;
        column-gap: 120px;
        justify-content:space-evenly;
        background-color: #00000056;
        padding: 20px;
    }
    
    .secao1{
        display: flex;
        flex-direction: column;
    }
    
    .secao1 .img{
        padding-bottom: 25px;
    }
    
    .secao1 a{
        color: #c0c0c0;
        text-decoration: none;
        font-weight: 200;
        font-size: 14px;
        padding-bottom: 10px;
        transition: ease-in-out 0.2s;
    }
    
    .secao1 a:hover{
        color: #ff863b;
    }
    
    .secao2{
        display: flex;
        flex-direction: column;
    }
    
    .secao2 h2{
        font-weight: 400;
        font-size: 22px;
        color: #fff;
    }
    
    .secao2 a{
        color: #c0c0c0;
        text-decoration: none;
        font-weight: 200;
        font-size: 14px;
        padding-bottom: 5px;
        transition: ease-in-out 0.2s;
    }
    
    .secao2 a:hover{
        color: #ff863b;
    }
    
    .secao3{
        display: flex;
        flex-direction: column;
    }
    
    .secao3 h2{
        font-weight: 400;
        font-size: 22px;
        color: #fff;
        margin-bottom: 1px;
    }
    
    .secao3 p{
        font-weight: 300;
        font-size: 18px;
        color: #fff;
        margin-bottom: 8px;
        border-bottom: 2px solid #ffffff28;
        padding-bottom: 3px;
    }
    
    .secao3 span{
        font-weight: 200;
        font-size: 14px;
        color: #ff863b;
    }
    
    .secao4 h2{
        font-weight: 400;
        font-size: 22px;
        color: #fff;
    }
    
    .secao4 a{
        color: #c0c0c0;
        text-decoration: none;
        font-weight: 200;
        font-size: 14px;
        padding-bottom: 5px;
        transition: ease-in-out 0.2s;
    }
    
    .secao4 a:hover{
        color: #ff863b;
    }
    
    .secao5 .redes{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
    
    .secao5 h2{
        font-weight: 400;
        font-size: 22px;
        color: #fff;
    }
    
    .secao5 .redes a svg{
        color: #c0c0c0;
        transition: ease-in-out 0.4s;
        height: 30px;
    }
    
    .secao5 .redes a svg:hover{
        color: #ff863b;
    }
}