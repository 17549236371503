.containerFAQ{
    padding-bottom: 40px;
    /*background-color: #00000056;*/
    margin-bottom: 30px;
    overflow-x: hidden;
}

.containerFAQ h2{
    text-align: center;
    font-weight: 200;
    font-size: 20px;
    padding-bottom: 20px;
    color: #ff863b
}

.FAQ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.FAQ details{
    width: 90%;
    max-width: 700px;
    cursor: pointer;
    border-radius: 30px 0px 30px 0px;
    background-color: #06080f;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)' opacity='0.15'/%3E%3C/svg%3E");
    box-shadow: 5px 5px 10px #000000de;
}

.FAQ details .FAQTitulo{
    color: #ffffff;
    font-weight: 400;
    padding: 10px;
    font-size: 12px;
}

.FAQTitulo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.FAQTitulo::marker, .FAQTitulo::-webkit-details-marker {
    display: none;
}

.summaryIcon {
    transition: transform 0.5s ease-in-out;
}

.summaryIcon{
    color: #fff;
}

details[open] .summaryIcon {
    transform: rotate(90deg);
}

.FAQ details .FAQResposta{
    color: #919191;
    font-weight: 300;
    margin: 0px;
    padding: 10px;
    text-align: justify;
    font-size: 12px;
}

/* TABLETS */
@media screen and (min-width: 480px) {}

/* LAPTOPS OU TELAS MENORES */
@media screen and (min-width: 768px) {}

/* DESKTOP */
@media screen and (min-width: 1024px) {}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .containerFAQ{
        padding-bottom: 60px;
        margin-bottom: 100px;
    }
    
    .containerFAQ h2{
        text-align: center;
        font-weight: 200;
        font-size: 24px;
        padding-bottom: 20px;
        color: #ff863b
    }
    
    .FAQ{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 25px;
    }
    
    .FAQ details{
        width: 60%;
        max-width: 700px;
        cursor: pointer;
        border-radius: 30px 0px 30px 0px;
        background-color: #06080f;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)' opacity='0.15'/%3E%3C/svg%3E");
        box-shadow: 5px 5px 10px #000;
    }
    
    .FAQ details .FAQTitulo{
        color: #ffffff;
        font-weight: 400;
        padding: 15px;
        font-size: 14px;
    }
    
    .FAQTitulo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .FAQTitulo::marker, .FAQTitulo::-webkit-details-marker {
        display: none;
    }
    
    .summaryIcon {
        transition: transform 0.5s ease-in-out;
    }
    
    .summaryIcon{
        color: #fff;
    }
    
    details[open] .summaryIcon {
        transform: rotate(90deg);
    }
    
    .FAQ details .FAQResposta{
        color: #919191;
        font-weight: 300;
        margin: 0px;
        padding: 15px;
        text-align: justify;
        font-size: 13px;
    }
}