.footer{
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    overflow-x: hidden;
}

.footer p{
    color: #ffffff7a;
    font-weight: 200;
    font-size: 12px;
    padding-bottom: 0px;
}

.footer span{
    font-weight: 300;
    font-size: 12px;
    padding-bottom: 0px;
    text-shadow: 3px 3px 4px rgba(255,134,59,1);
    animation: piscar 0.5s infinite;
}

@keyframes piscar {
    0%{
        color: #fff;
        text-shadow: 3px 3px 7px rgba(255,134,59,1);
    }

    50%{
        color: #ffffff44;
        text-shadow: 3px 3px 7px #000;
    }

    100%{
        color: #fff;
        text-shadow: 3px 3px 7px rgba(255,134,59,1);
    }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

/* DESKTOP MAIORES */
@media screen and (min-width: 1200px) {
    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 5px;
    }
    
    .footer p{
        color: #ffffff7a;
        font-weight: 200;
        font-size: 14px;
        padding-bottom: 0px;
    }
    
    .footer span{
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 0px;
        text-shadow: 3px 3px 4px rgba(255,134,59,1);
        animation: piscar 0.5s infinite;
    }
}